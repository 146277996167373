//Essentials
import React, { useState } from "react";
import "./events.scss";
//Components
import MyCalendar from "../../components/Calendar";
import { Row, Col, Button } from "antd";
import EventFlyer from "../../components/EventFlyer/EventFlyer";
import FormModal from "../../components/FormModal/FormModal"
import webinarImage from "../../assets/images/webinarFlyer.png"
import { useHistory, useParams } from "react-router-dom"

//Assets
import { GOOGLE_CALENDAR } from "../../utils/constants";

export default function Events() {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const history = useHistory()
  const params = useParams()

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleOpenModal = () => {
    setIsModalVisible(true)
  }

  const handleOpenLink = () => {
    window.open('https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTQ3ZmM5MzItMDA5Ny00MmY4LThiZDctNmM1ODJlMjlkZWIz%40thread.v2/0?context=%7b%22Tid%22%3a%2286ecffcf-0b1b-4d7e-be62-9987d6a36f19%22%2c%22Oid%22%3a%22e51fdbaa-3660-4489-b251-30649371deb2%22%7d', '_blank')
  }

  return (
    <div className="events">
      <FormModal
        title="Formulario de Inscripción"
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      >
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdF7fPq9vAJ1oYdKykumq9F-A1EY9yrOeF2fh8tClL-Xb9whg/viewform?usp=sf_link" width="640" height="1266" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
      </FormModal>
      <Row justify="center">
        <Col span={24}>
          <h2>Calendario de Eventos</h2>
        </Col>
      </Row>
      <Row justify="center">
        <Row style={{ gap: 20 }}>
          <Button onClick={() => handleOpenModal()} type="primary">Inscribirse</Button>
          <Button onClick={() => handleOpenLink()} type="primary">Conectarse</Button>
        </Row>
        <Col span={24}>
          <EventFlyer imageSource={webinarImage} alt="Imagen Webinar" />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <MyCalendar source={GOOGLE_CALENDAR} />
        </Col>
      </Row>
    </div>
  );
}
